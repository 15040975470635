const { search, searchParams } = new URL(document.location.href);
const referrer = search?.split("?")[1] || "";
const utm_source = searchParams.get("utm_source");

const userAgent = navigator.userAgent || navigator.vendor;

if (/android/i.test(userAgent)) {
  window.location.href = `https://play.google.com/store/apps/details?id=me.rainbow?referrer=${encodeURIComponent(
    referrer
  )}`;
} else if (/iPad|iPhone|iPod/i.test(userAgent)) {
  window.location.href = `https://apps.apple.com/app/apple-store/id1457119021${
    utm_source === "rainbowkit" ? "?pt=119997837&ct=rainbowkit&mt=8" : search
  }`;
} else {
  window.location.href = `https://rainbow.me/download${search}`;
}
